.images {
    &__content {
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        gap: 10px;
        height: 100%;
        width: 100%;
    }

    &__rsc {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 5px 10px 0 10px;
        height: 100%;
        overflow-y: auto;

        &__toggle {
            justify-content: space-between;
        }

        &__download {
            margin-top: auto;
        }
    }

    .calendar {
        &--mobile {
            width: 120px;
        }
    }
}
.toggle-content {
    display: flex;
    flex-direction: row;
    margin: 12px 4px;

    &__color-dot {
        width: 12px;
        height: 12px;
        margin: 2px 0px;
    }

    &__label {
        font-size: 12px;
        font-style: italic;
        margin-left: 8px;
        line-height: 1.4;

        &__count {
            font-weight: bold;
        }
    }
}

.process-bar-container {
    margin-top: 16px;

    &__top-label {
        font-size: 12px;
        font-style: italic;
        margin-bottom: 12px;
        display: flex;
    }

    &__bottom-label {
        font-size: 12px;
        font-style: italic;
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
    }
}

.progress-bar {
    display: flex;
    flex-direction: row;
    height: 12px;
    align-items: center;

    &__line-description {
        height: 24px;
        border-left: 1px solid grey;

        &__left { margin-bottom: 12px; }

        &__right { margin-top: 12px; }
    }
}