.radio-collapse {
    display: flex;
    flex-direction: column;

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        &_radio-control {
            display: flex;
            align-items: center;

            /* The switch - the box around the slider */
            &__switch {
                position: relative;
                display: inline-block;
                width: 18px;
                height: 18px;

                /* The slider */
                &__slider {
                    border-radius: 25px;
                    background: var(--secondary);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transition: 0.4s;
                    -webkit-transition: 0.4s;
                    cursor: pointer;
                }
            }
        }

        &__label {
            font-size: 12px;
            margin: 0px 6px;
        }

    }


    &__navigation-arrow {
        align-self: center;
        font-size: 12px;
        color: white;
        cursor: pointer;
    }

    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked+.radio-collapse__toggle_radio-control__switch__slider {
        background: var(--primary);
    }

    input:checked+.radio-collapse__toggle_radio-control__switch__slider:before {
        transform: translateX(26px);
        -ms-transform: translateX(26px);
        -webkit-transform: translateX(26px);
    }
}