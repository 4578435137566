.coordinate-annotation {
    position: absolute;
    background-color: transparent;
    font-size: 0.7em;

    &__label {
        position: absolute;
        bottom: 100%;
        left: -2px;
        padding: 0.2rem 0.5rem;
        white-space: nowrap;
        opacity: 0.8;

        &:hover {
            opacity: 1;
            z-index: 10;
        }
    }
}