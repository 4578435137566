.slider-custom {
    width: 100%;

    &--slider {
        width: 100%;
        -webkit-appearance: none;
        height: 7px;
        background: var(--secondary);
        border-radius: 5px;
        // background-image: linear-gradient(var(--secondary), var(--secondary));
        background-image: linear-gradient(#eb9000, #eb9000);
        background-repeat: no-repeat;
        
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            // background: var(--secondary);
            background: white;
            cursor: pointer;
            box-shadow: 0 0 2px 0 #555;
        }
        &::-webkit-slider-runnable-track {
            -webkit-appearance: none; 
            box-shadow: none; 
            border: none; 
            background: transparent; 
        }
    }

    &--label {
        font-size: 12px;
        margin: 0px 6px;
    }

}